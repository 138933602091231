import {isServer} from "@/shared/app/isServer";

export const polyfillRequestIdleCallback = (!isServer && window.requestIdleCallback) ||
    // Polyfill for requestIdleCallback for Safari and IE
    function (handler) {
        // Fallback to setTimeout if requestIdleCallback is not supported
        const start = Date.now();
        return setTimeout(() => {
            handler({
                didTimeout: false,
                timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
            });
        }, 1);
    };
