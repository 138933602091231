import { Category } from '../api/arena-data';
import { GameState } from '../enums/GameState';

export const ALL_CATEGORY = {
    name: 'See All',
    id: -1,
    slug: '',
    image: null,
    carousels: [],
    arena_tags: [],
    seo: null,
    title: null,
    description: null,
} as Category;
export const ALPHABETICAL_SORT_LABEL = 'Alphabetically';
export const DEFAULT_ROOT_CATEGORIES_SLUG = 'categories';

export const toExcludeQSP = [
    'ark__platformtype',
    'utm',
    'ark__environment',
    'ark__nextjs_next_url',
    'ark__next_router_prefetch',
    'ark__next_router_state_tree',
    'BAD_QSP',
];

export const DEFAULT_LOCALE = 'en';
export const GAME_STATES = [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL];
