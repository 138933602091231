import { GroupLinks } from '@/components/Footer';
import { TLink } from '../app';
import { Endpoints, fetchData, strapiOptions } from './data-fetcher';
import { TSeoType } from './seo';
import { IModularPageBase } from './pages';

export type ThemeLayout = {
    id: number;
    theme: string;
    layout: 'wide' | 'centered';
    arenaMaxWidth: number;
    fontFamily: string;
    webKitVersion: string;
    mode: 'minimalistic' | 'modern';
    roundCorners: boolean;
    carouselArrowsVisible: boolean;
};

export type ButtonOverride = {
    state: 'normal' | 'hover' | 'active';
    border?: string | null;
    background?: string | null;
    background_end?: string | null;
    color?: string | null;
};

export type StyleOverride = {
    bigHeroCard: {
        description: string | null;
        overlay: string | null;
        title: string | null;
        category: string | null;
        button: ButtonOverride[] | null;
    } | null;
    marketingCard: {
        description: string | null;
        overlay: string | null;
        button: ButtonOverride[] | null;
    } | null;
    seoCard: {
        title: string | null;
        description: string | null;
        button: ButtonOverride[] | null;
    } | null;
    standardCard: {
        title: string | null;
        hoverBlock: {
            type: 'partial' | 'full' | 'none' | null;
            color: string | null;
            background: string | null;
            buttonText: string | null;
            buttonBackground: string | null;
            buttonBackground_end: string | null;
            buttonTextColor: string | null;
            buttonBorderColor: string | null;
        };
    } | null;
    promoCard: {
        description: string | null;
        overlay: string | null;
        title: string | null;
        category: string | null;
        button: ButtonOverride[] | null;
    } | null;
    portraitCard: {
        hoverBlock: {
            type: 'partial' | 'full' | 'false' | null;
            color: string | null;
            background: string | null;
        };
    } | null;
    horizontalGenericLinks: {
        border_color: string | null;
        border_color_hover: string | null;
        border_color_active: string | null;
        label_color: string | null;
        label_color_hover: string | null;
        label_color_active: string | null;
        background_color: string | null;
        background_color_end: string | null;
        background_color_hover: string | null;
        background_color_hover_end: string | null;
        background_color_active: string | null;
        background_color_active_end: string | null;
    };
    horizontalGenericLinksSearch: {
        border_color: string | null;
        border_color_hover: string | null;
        border_color_active: string | null;
        label_color: string | null;
        label_color_hover: string | null;
        label_color_active: string | null;
        background_color: string | null;
        background_color_end: string | null;
        background_color_hover: string | null;
        background_color_hover_end: string | null;
        background_color_active: string | null;
        background_color_active_end: string | null;
    };
    topbar: { background: string | null } | null;
    topbarLink: ButtonOverride[] | null;
    sidebar: {
        divider: string | null;
        background: string | null;
        toggle: string | null;
        toggleBackground: string | null;
    } | null;
    sidebarItem: ButtonOverride[] | null;
    searchGoToHomepage: ButtonOverride[] | null;
    viewMore: ButtonOverride[] | null;
    tags: ButtonOverride[] | null;
} | null;

type SideBarItem = {
    icon: { url: string };
    link: TLink;
};

type ArenaGameFeedId = {
    id: number;
    feedId: number;
};

export type Tag = {
    slug: string;
    name: string | null;
    image: { url: string } | null;
    carousels: any[] | null;
    seo: TSeoType | null;
    title: string | null;
    description: string | null;
};
export type Category = {
    id: number;
    slug: string;
    name: string | null;
    image: { url: string } | null;
    arena_tags: Tag[] | null;
    carousels: any[] | null;
    seo: TSeoType | null;
    title: string | null;
    description: string | null;
};

export type Badge = {
    id: number;
    label: string;
    backgroundColor: string;
    labelColor: string;
    arena: any;
};

type TLocaleList = {
    name: string;
    list: TLocale[] | null;
};
export type TLocale = {
    id: number;
    code: string;
};
type TheySellAd = {
    displayAdUrl: string;
    videoAdUrl: string;
};
export type TExternalScriptLoadingType = 'cdnURL' | 'rawScript' | 'internal';
export type TExternalScriptRaw = {
    id: number;
    name: string;
    script: {
        mainScript: string;
        scriptLoadingType: TExternalScriptLoadingType;
        codeBeforeMainScript?: string;
        codeAfterMainScript?: string;
        isLoaded?: boolean;
    };
};
export type TExternalScript = Omit<TExternalScriptRaw, 'script'> & TExternalScriptRaw['script'];
export type ArenaDataState = {
    layout: {
        themeLayout: ThemeLayout | null;
        styleOverride: StyleOverride | null;
        analyticId: string | null;
        topBar: boolean | null;
        topBarLinks: TLink[];
        sideBar: boolean | null;
        sidebar_groups:
            | {
                  sidebar_items: SideBarItem[];
                  icon: { url: string };
                  link: TLink;
              }[]
            | null;
        footer: {
            logo: { url: string } | null;
            groups: GroupLinks[] | null;
            socialLinks: TLink[] | null;
            background: string | null;
            logoUrl: string | null;
            fontColor: string | null;
            text: string | null;
        } | null;
        categoryTagAdsDivider: boolean | null;
        categoryTagAdsDividerInterval: number | null;
        buttonsAndPillsUseBorder: boolean | null;
    } | null;
    customScripts: string | null;
    analyticId: string | null;
    game_page: { id: number } | null;
    logo: { url: string } | null;
    favIcon: { url: string } | null;
    game_feed: ArenaGameFeedId | null;
    arena_categories: Category[] | null;
    arena_tags: Tag[] | null;
    arena_badges: any | null;
    categoriesSlug: string | null;
    localeList: TLocaleList | null;
    theySellAd: TheySellAd | null;
    external_scripts: TExternalScriptRaw[] | null;
};

export const fetchArenaData = async (domain: string): Promise<ArenaDataState> => {
    const url = Endpoints.getArenaData(domain);
    return await fetchData(url, strapiOptions);
};

export const fetchArenaLocales = async (domain: string): Promise<ArenaDataState> => {
    const url = Endpoints.getArenaLocales(domain);
    return await fetchData(url, strapiOptions);
};

export type TArenaDataLocaleAndPages = {
    categoriesSlug: ArenaDataState['categoriesSlug'];
    localeList: ArenaDataState['localeList'];
    modularPageGroups: Array<{ modular_pages: Partial<IModularPageBase>[] }>;
};
export const fetchArenaDataByQuery = async (query: string): Promise<TArenaDataLocaleAndPages> => {
    const url = Endpoints.getArenaDataByQuery(query);
    const res = await fetchData(url, strapiOptions);
    return res?.data?.[0];
};
