import { logger, configureLogger } from '@arkadium/modules/dist/lib/_shared/utilites/logger';
import { createEffect, sample } from 'effector';

export const configureLoggerFx = createEffect<boolean, null>();

sample({
    clock: configureLoggerFx,
    fn: (value) => {
        if (value) {
            configureLogger(value);
        }
        return null;
    },
});

export { logger };
